import type { UpsertMandateBody } from '@api/mandates';
import type { CountryCode } from '@utils/types/countries';
import type { MandateFormType } from '@utils/types/mandate';
import pick from 'lodash/pick';

export const convertSiretsString = (siretsString: string): string[] =>
  siretsString
    .split(',')
    .flatMap((s) => s.split('\n'))
    .filter((s) => s !== '');

export const formatUpsertBody = (
  values: MandateFormType,
  defaultCountry?: CountryCode | null,
  defaultGridId?: number | null,
): UpsertMandateBody => {
  let country: CountryCode = values['country'];
  if (!country && defaultCountry) country = defaultCountry;

  let grid_id: number | null = values['grid_id'];
  if (!grid_id && defaultGridId) grid_id = defaultGridId;

  const body: UpsertMandateBody = {
    ...pick(values, ['signatory_name', 'signatory_email', 'sign_type', 'mandate_file']),
    country,
    grid_id,
    sirets: convertSiretsString(values.sirets),
  };

  return body;
};

export const formatUploadBody = (file: File | null) => {
  const formData = new FormData();
  if (file) formData.append('file', file);

  return formData;
};
