import React, { useDeferredValue, useState } from 'react';
import { useUser } from '@context/User.context';
import { styles } from '@GDM/Filters';
import { RadioButtons, sectionStyles } from '@GDM/forms';
import { HelpBox } from '@GDM/HelpBox';
import { Toggle } from '@GDM/Toggle';
import useTranslation from '@hooks/useTranslation';
import { FINANCIAL_CONTRACTS, contractTypeList, type ContractTypeOption } from '@utils/constants';
import type { Option } from '@utils/types/common-types';
import {
  DELIVERY_PROFILES,
  type Classification,
  type Contract,
  type ContractForm,
  type ContractType as TContractType,
  type DeliveryProfile,
} from '@utils/types/contract';
import type { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';
import { getDefaultDirection } from '../../helpers/getDefaultDirection';
import contractTypePickerStyles from './styles/contract-type-picker.module.scss';

export const ContractType = ({
  title,
  readOnly,
  globals: { formMode, installation },
  form: { control, watch },
}: ContractFormSectionProps) => {
  const { account_type, main_country } = useUser();
  const installationCountry = installation?.country || main_country || 'FR';
  const { t } = useTranslation();

  const [contractTypeChangeEnabled, setContractTypeChangeEnabled] = useState<boolean | undefined>(formMode !== 'edit');

  const contractType = watch('type');
  const direction = watch('direction', getDefaultDirection(account_type));
  const deferredDirection = useDeferredValue(direction);

  const adjustedDirection =
    account_type === 'aggregator' ? AGGREGATOR_DIRECTION_MAP[deferredDirection] : deferredDirection;

  const isGLSFInstallation =
    typeof installation?.has_meter === 'boolean' && installation.status !== 'draft' ? !installation.has_meter : false;

  const contractTypeOptions = contractTypeList[isGLSFInstallation ? 'GLSF' : installationCountry].filter(
    (option) => option.direction === adjustedDirection,
  );

  const deliveryProfileOptions = getDeliveryProfiles(contractType, installationCountry).map((profile) => ({
    label: `sales_management.${profile}`,
    value: profile,
  }));

  return (
    <FormSectionLayout
      title={title}
      body={
        <div className={sectionStyles.container} data-cy="contract-type">
          <div className="p-3">
            <div>
              <div className={contractTypePickerStyles.wrapper}>
                {formMode === 'edit' && (
                  <Toggle
                    value={contractTypeChangeEnabled}
                    onChange={setContractTypeChangeEnabled}
                    tooltip="sales_management.info.contract_type_change"
                    className={styles.toggle}
                  />
                )}
                <ListInput
                  className={contractTypePickerStyles.select}
                  id="ContractTypePicker"
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  label="sales_management.contract_type"
                  options={contractTypeOptions || []}
                  isDisabled={!installation || !contractTypeChangeEnabled}
                  readOnly={readOnly}
                  classNamePrefix="select-contract-type"
                  menuPlacement="top"
                  isOptionDisabled={(option: unknown) => (option as ContractTypeOption).disabled === true}
                  isSearchable
                  helpBox={{ title: 'sales_management.contract_type', text: 'contracts.info.contract_type' }}
                  defaultValue={null}
                />
              </div>
              {!readOnly && contractType && CONTRACT_TYPE_HELP_BOXES[contractType] && (
                <HelpBox text={CONTRACT_TYPE_HELP_BOXES[contractType]} className="mb-3" />
              )}
            </div>

            <div className={classNames(sectionStyles.row)}>
              <RadioButtons
                label="sales_management.classification"
                options={[
                  { label: 'sales_management.physical', value: 'physical' },
                  { label: 'sales_management.financial', value: 'financial' },
                ]}
                selected={getClassificationValue(contractType)}
                size="lg"
                containerClassName="fit"
                disabled
                tooltip={
                  contractType
                    ? t('sales_management.classification_info_contract', {
                        contractType: t(getClassificationLabel(contractType, installationCountry)),
                        classification: t(`sales_management.${getClassificationValue(contractType)}`),
                      })
                    : 'sales_management.classification_info_select'
                }
              />

              {deliveryProfileOptions.length > 1 && (
                <ListInput
                  control={control}
                  name="delivery_profile"
                  className="wider"
                  label="sales_management.delivery_profile"
                  options={deliveryProfileOptions}
                  readOnly={readOnly}
                  isOptionDisabled={(option: Option<DeliveryProfile | null>) =>
                    ['pay_as_nominated', 'pay_as_fixed_profile'].includes(option?.value || '')
                  }
                  defaultValue="pay_as_produced"
                  helpBox={{
                    text: 'sales_management.delivery_profile_info',
                    title: 'sales_management.delivery_profile',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const CONTRACT_TYPE_HELP_BOXES: Partial<Record<TContractType, string>> = {
  ContractOa: 'contracts.info.oa',
  ContractCr: 'contracts.info.cr',
  ContractCrEdf: 'contracts.info.cr_edf',
  ContractSwap: 'contracts.info.swap',
};

const AGGREGATOR_DIRECTION_MAP: { [direction in Contract['direction']]: Contract['direction'] } = {
  sell: 'buy',
  buy: 'sell',
};

export const getClassificationValue = (contractType: ContractForm['type']): Classification | null => {
  if (!contractType) {
    return null;
  }

  return FINANCIAL_CONTRACTS.has(contractType) ? 'financial' : 'physical';
};

const getClassificationLabel = (contractType: ContractForm['type'], country: CountryCode) => {
  const label = contractTypeList[country]?.find((item) => item.value === contractType)?.label;

  if (!label) {
    return contractType;
  }

  return label;
};

const CONFIGURABLE_FIELDS = ['classification'] as const;

export type ConfigurableField = typeof CONFIGURABLE_FIELDS[number];

const getDeliveryProfiles = (
  contractType: ContractForm['type'],
  country: CountryCode | null,
): readonly DeliveryProfile[] => {
  if (
    ['ContractSoa', 'ContractCppa'].includes(contractType || '') ||
    (contractType === 'ContractSwap' && country === 'FR')
  ) {
    return DELIVERY_PROFILES;
  }

  return ['pay_as_produced'];
};
