import React, { CSSProperties, InputHTMLAttributes, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button, ButtonProps } from '@GDM/Button';
import { Icon, IconNames } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { getInputValue } from '@utils/formatters/getInputValue';
import { URL } from '@utils/regexes';
import { generateUuid } from '@utils/string';
import { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import { Select } from '../Select';
import styles, { container, input } from './input.module.scss';

export type InputProps<T extends string | number = string> = {
  className?: string;
  containerClassName?: string;
  errorMessage?: string | null;
  full?: boolean;
  flexFull?: boolean;
  hasError?: boolean;
  icon?: IconNames;
  tooltip?: string | null;
  inline?: boolean;
  label?: string;
  labelStyle?: CSSProperties;
  prefix?: string | JSX.Element | null;
  size?: 'lg';
  suffix?: React.ReactNode;
  suffixButtonProps?: ButtonProps;
  suffixOptions?: Option<T>[];
  suffixOptionSelected?: T;
  suffixOptionsDisabled?: boolean;
  onChangeSuffixOption?: (value: Option<T> | null) => void;
  iconOnClick?: () => void;
  warningMessage?: string | null;
  infoMessage?: string | null;
  'data-cy'?: string;
};

function BaseInput<T extends string>(
  {
    autoComplete,
    className,
    containerClassName,
    disabled,
    errorMessage,
    infoMessage,
    warningMessage,
    full,
    hasError,
    icon,
    id,
    tooltip,
    inline,
    label,
    labelStyle,
    name,
    onClick,
    placeholder,
    prefix,
    size,
    suffix,
    type,
    value,
    iconOnClick,
    flexFull,
    suffixOptionSelected,
    suffixOptions,
    suffixOptionsDisabled,
    onChangeSuffixOption,
    suffixButtonProps,
    ...props
  }: Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> & InputProps<T>,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);
  const user = useUser();
  const { t } = useTranslation();
  const htmlID = id || generateUuid();

  const classList = classNames(input, className, {
    [styles.full]: full,
    [styles['flex-full']]: flexFull,
    [styles.inline]: inline,
  });

  // Matches a regex for urls
  const isUrl = (v: typeof value): v is string => Boolean(v && `${v}`.match(new RegExp(URL, 'g')));

  return (
    <div className={classList}>
      {!!label && (
        <label htmlFor={htmlID} style={labelStyle}>
          <span title={t(label)} dangerouslySetInnerHTML={label ? { __html: t(label) } : undefined} />
          {tooltip && <Icon name="Info" size={14} title={tooltip} className="ml-1" />}
        </label>
      )}
      <div
        className={classNames(
          container,
          { [styles['focused']]: isFocused },
          { [styles['has-error']]: !!errorMessage || hasError },
          { [styles['has-warning']]: !!warningMessage },
          { [styles.disabled]: disabled },
          { [styles['suffix-button']]: !!suffixButtonProps },
          { [styles['has-select']]: !!suffixOptions },
          containerClassName,
          size && styles[size],
        )}
      >
        {icon && (
          <Icon
            name={icon}
            size={size === 'lg' ? 16 : 14}
            onClick={iconOnClick}
            style={{ cursor: iconOnClick ? 'pointer' : 'default' }}
          />
        )}
        {prefix && <div className={styles.prefix}>{typeof prefix === 'string' ? t(prefix) : prefix}</div>}
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={ref}
          id={htmlID}
          type={props.readOnly ? 'text' : type}
          placeholder={props.readOnly ? '--' : t(placeholder || '')}
          name={name}
          value={
            props.onChange || props.readOnly || disabled ? getInputValue(value, type, props.readOnly, user) : undefined
          }
          disabled={disabled}
          onClick={onClick}
          onFocus={(e) => {
            props.onFocus?.(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            props.onBlur?.(e);
            setIsFocused(false);
          }}
          autoComplete={autoComplete}
          // Prevent value changing when user scrolls in number inputs
          onWheel={type === 'number' ? (e) => e.currentTarget.blur() : undefined}
        />
        {!!suffix && typeof suffix === 'string' ? <span className={styles.suffix}>{t(suffix)}</span> : suffix}
        {suffixOptions && (
          <Select
            options={suffixOptions}
            onChange={onChangeSuffixOption}
            className={styles.select}
            selectedOption={suffixOptionSelected}
            size={size === 'lg' ? 'lg' : undefined}
            isDisabled={suffixOptionsDisabled}
            isSearchable={false}
          />
        )}
        {!!suffixButtonProps && (
          <Button
            size={size === 'lg' ? 'sm' : 'xs'}
            {...suffixButtonProps}
            className={classNames(size && styles[size], suffixButtonProps.className)}
          />
        )}
        {isUrl(value) && props.readOnly && (
          <Button className={styles['external-link']} target="_blank" href={value} icon="ExternalLink" floating />
        )}
      </div>
      {!!errorMessage && (
        <Text
          size="sm"
          className="mt-1"
          type="danger"
          text={errorMessage}
          data-cy={props['data-cy'] ? `${props['data-cy']}-error` : undefined}
        />
      )}
      {!!warningMessage && <Text size="sm" className="mt-1" type="warning" text={warningMessage} />}
      {!!infoMessage && <Text size="sm" className="mt-1" type="normal" text={infoMessage} />}
    </div>
  );
}

export const Input = React.forwardRef(BaseInput) as <T extends string>(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> &
    InputProps<T> & { ref?: React.Ref<HTMLInputElement> },
  ref: React.Ref<HTMLInputElement>,
) => JSX.Element;
