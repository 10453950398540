import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';

export const SignTypeSelect = ({ disabled }: { disabled?: boolean }) => {
  const form = useInstallationForm();

  const options = [
    { value: 'online', label: 'common.e_signature' },
    { value: 'offline', label: 'common.manuel' },
  ];

  return (
    <ControlledSelect
      control={form.control}
      disabled={disabled}
      options={options}
      label="common.signature"
      name="mandate.sign_type"
      size="lg"
    />
  );
};
