import React from 'react';
import { DataProvider } from './DataProvider';
import styles from './default.module.scss';
import { DirectConnection } from './DirectConnection';

export const Default = () => {
  return (
    <div className={styles.container}>
      <DataProvider />
      <DirectConnection />
    </div>
  );
};
