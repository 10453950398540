import React from 'react';
import { Select } from '@GDM/forms/Select/Select';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Grid } from '@utils/types/grid';

export const GridSelect = ({
  grids,
  onChange,
  value,
  isLoading,
  disabled,
}: {
  grids: Grid[];
  onChange: (grid: number | null) => void;
  value: number | null;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const options = grids.map((grid) => ({ label: grid.name, value: grid.id })).sort(sortOptionsByLabelAsc);

  return (
    <Select
      options={options}
      selectedOption={options.find((option) => option.value === value)?.value}
      onChange={(option) => (option ? onChange(option.value) : onChange(null))}
      isLoading={isLoading}
      isDisabled={disabled}
      label="common.grid"
    />
  );
};
