import React from 'react';
import { Input } from '@GDM/forms';

export const SignatoryEmailInput = ({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  return (
    <Input
      full
      label="admin.installations.signatory_email"
      value={value}
      onChange={onChange}
      disabled={disabled}
      data-cy="signatory-email-input"
    />
  );
};
