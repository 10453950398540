import React, { useState } from 'react';
import { Tab, Tabs } from '@GDM/Tabs/Tabs';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { RiskAnalysisDataViz } from './DataViz';
import { RiskAnalysisFormFilters, RiskAnalysisReportType } from './types';

const TABS: Tab<RiskAnalysisReportType>[] = [
  { id: 'valuation', name: 'risk_analysis.valuation' },
  { id: 'sensitivity', name: 'risk_analysis.sensitivity' },
];
const DEFAULT_PERIOD: [Date, Date] = [dayjs().subtract(1, 'year').toDate(), dayjs().add(5, 'year').toDate()];

const InternalRiskAnalysis = ({ user }: { user: User }) => {
  const [currentTab, setCurrentTab] = useState<RiskAnalysisReportType>(
    (window.location.hash.replace('#', '') as RiskAnalysisReportType) || 'valuation',
  );

  const valuationForm = useForm<RiskAnalysisFormFilters>({
    defaultValues: {
      period: DEFAULT_PERIOD,
      primaryFocus: 'contract_type',
      metricGroup: 'volume',
      splitBy: 'split_by_focus',
      granularity: 'monthly',
      display: 'chart',
    },
  });

  const sensitivityForm = useForm<RiskAnalysisFormFilters>({
    defaultValues: {
      period: DEFAULT_PERIOD,
      primaryFocus: 'contract_type',
      metricGroup: 'all',
      display: 'table',
    },
  });

  return (
    <Page title="risk_analysis.risk_analysis" user={user}>
      <Tabs tabs={TABS} onTabChange={setCurrentTab}>
        {currentTab === 'valuation' && <RiskAnalysisDataViz form={valuationForm} type="valuation" />}
        {currentTab === 'sensitivity' && <RiskAnalysisDataViz form={sensitivityForm} type="sensitivity" />}
      </Tabs>
    </Page>
  );
};

export default InternalRiskAnalysis;
