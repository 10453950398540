import React from 'react';
import { UploadInput } from '@GDM/UploadInput/UploadInput';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';

export const UploadFile = ({ disabled }: { disabled?: boolean }) => {
  const form = useInstallationForm();

  return (
    <UploadInput
      {...form.register('mandate.mandate_file')}
      placeholder="admin.installations.insert_mandate"
      fileTypes={['.pdf']}
      disabled={disabled}
      onClear={() => form.resetField('mandate.mandate_file')}
    />
  );
};
