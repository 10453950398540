import React, { useEffect } from 'react';
import { Button } from '@GDM/Button';
import useAxiosDeprecated from '@hooks/useAxios';
import { download_v2_mandate_path, template_pdf_v2_mandates_path } from '@utils/routes';
import { Mandate, MandateFormType } from '@utils/types/mandate';
import fileDownload from 'js-file-download';

const cleanSirets = (sirets: string) =>
  sirets
    ?.split(',')
    .flatMap((s) => s.split('\n'))
    .filter((s) => s !== '')
    .map((s) => s.replace(/^\s+|\s+$/g, ''));

const GeneratePDFButton = ({ form, selectedMandate }: { form: MandateFormType; selectedMandate: Mandate | null }) => {
  const [generateRequest, generate] = useAxiosDeprecated({
    url: template_pdf_v2_mandates_path(),
    method: 'GET',
    responseType: 'blob',
  });

  const [downloadRequest, download] = useAxiosDeprecated({
    url: selectedMandate ? download_v2_mandate_path(selectedMandate.uuid) : undefined,
    method: 'GET',
    responseType: 'blob',
  });

  const disabled = !form?.sirets || !form.signatory_name || !form.signatory_email;

  const handleDownload = () => {
    if (selectedMandate?.url) download();
    else
      generate({
        params: {
          sirets: form?.sirets ? cleanSirets(form.sirets) : [],
          name: form?.signatory_name,
          email: form?.signatory_email,
        },
      });
  };

  useEffect(() => {
    if (generateRequest.loading === 'LOADED' && generateRequest.data) fileDownload(generateRequest.data, 'mandate.pdf');
  }, [generateRequest.loading]);

  useEffect(() => {
    if (downloadRequest.loading === 'LOADED') fileDownload(downloadRequest.data, 'signed-mandate.pdf');
  }, [downloadRequest]);

  return (
    <Button
      variant="primary-2"
      onClick={handleDownload}
      disabled={disabled}
      className="mr-3"
      isLoading={generateRequest.loading === 'LOADING'}
      icon="Download"
      size="xs"
      text={generateRequest.loading === 'LOADING' ? 'admin.installations.generating' : 'common.download'}
    />
  );
};

export default GeneratePDFButton;
