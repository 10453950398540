import React, { useCallback, useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { Mandate, MandateStatus } from '@utils/types/mandate';
import { useInstallationsPageContext } from '../../context/installations-page.context';
import { FormMandates } from '../../form/FormMandates';
import RenderStatus from '../../form/text/RenderStatus';
import styles from './mandates.table.module.scss';

const StatusCell = ({ value }: { value: MandateStatus }) => <RenderStatus status={value} />;

export const MandatesTab = () => {
  const { mandateModalVisible, toggleMandateModal, addMandate, filteredMandates, loading } =
    useInstallationsPageContext();

  const [selectedMandate, setSelectedMandate] = useState<Mandate | null>(null);

  const handleClick = useCallback(
    (mandate: Mandate) => {
      setSelectedMandate(mandate);
      toggleMandateModal();
    },
    [setSelectedMandate, toggleMandateModal],
  );

  const user = useUser();

  const columns = useMemo<ColumnDef<Mandate>[]>(
    () =>
      [
        { header: 'common.representative', accessorKey: 'representative' },
        { header: 'common.start_date', accessorKey: 'start_date' },
        { header: 'common.end_date', accessorKey: 'end_date' },
        {
          header: 'common.active',
          accessorKey: 'status',
          cell: ({ getValue }) => <StatusCell value={getValue<MandateStatus>()} />,
        },
        ...(user.isAuthorized(['update:mandat'])
          ? ([
              {
                header: '',
                id: 'actions',
                enableSorting: false,
                cell: ({ row }) => (
                  <Button floating variant="primary-2" onClick={() => handleClick(row.original)} icon="Edit3" />
                ),
              },
            ] satisfies ColumnDef<Mandate>[])
          : []),
      ] satisfies ColumnDef<Mandate>[],
    [handleClick, user],
  );

  const table = useReactTable({
    columns,
    data: filteredMandates,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles['size-mandates-table']}>
      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} isLoading={loading} />
      </Table>

      <Modal
        isOpen={mandateModalVisible}
        toggle={() => {
          setSelectedMandate(null);
          toggleMandateModal();
        }}
        size="lg"
        header="admin.installations.add_mandate"
        hideCancelButton
      >
        <FormMandates
          selectedMandate={selectedMandate}
          onSuccess={(mandate) => (mandate ? addMandate(mandate) : null)}
          onClose={() => {
            setSelectedMandate(null);
            toggleMandateModal();
          }}
        />
      </Modal>
    </div>
  );
};
