import React from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import MarketPlayerConfig from '../../../../../../../../../config/data/market_players.yml';

const config = MarketPlayerConfig['shared'];

export const CompanyNumberInput = ({ disabled }: { disabled?: boolean }) => {
  const form = useInstallationForm();

  const country = form.watch('country');
  const label = (country && config[country]?.company_number_name) || 'owner.company_number';

  return (
    <ControlledInput
      control={form.control}
      disabled={disabled}
      label={label}
      name="mandate.sirets"
      type="text"
      size="lg"
      data-cy="sirets-input"
      infoMessage="common.separate_comma"
      flexFull
    />
  );
};
