import axiosInstance from '@utils/axiosInstance';
import { v2_mandate_path, v2_mandates_path } from '@utils/routes';
import { CountryCode } from '@utils/types/countries';
import type { Mandate, MandateFormType } from '@utils/types/mandate';

export type UpsertMandateBody = Pick<Mandate, 'signatory_name' | 'signatory_email' | 'sirets'> & {
  country: CountryCode;
  grid_id: number | null;
  mandate_file: MandateFormType['mandate_file'];
  sign_type: MandateFormType['sign_type'];
};

const getFormData = (body: UpsertMandateBody): FormData => {
  // since the mandate form may include files, we need to use form data to submit
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (key === 'sirets') formData.append('sirets', (body.sirets || []).join(','));
    else if (key === 'mandate_file' && body.mandate_file) formData.append('mandate_file', body.mandate_file[0]);
    else if (value) formData.append(key, value as string);
  });

  return formData;
};

export const getMandates = async () => {
  return (await axiosInstance(false).get<Mandate[]>(v2_mandates_path())).data;
};

export const getMandateById = async (uuid: Mandate['uuid'] | null) => {
  return (await axiosInstance(false).get<Mandate>(uuid ? v2_mandate_path(uuid) : '')).data;
};

export const createMandate = async (body: UpsertMandateBody) => {
  const formData = getFormData(body);

  return (await axiosInstance(false).post<Mandate>(v2_mandates_path(), formData)).data;
};

export const updateMandate = async (uuid: Mandate['uuid'] | null, body: UpsertMandateBody) => {
  const formData = getFormData(body);

  return (await axiosInstance(false).patch<Mandate>(uuid ? v2_mandate_path(uuid) : '', formData)).data;
};
