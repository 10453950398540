import React from 'react';
import { Button } from '@GDM/Button';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { template_pdf_v2_mandates_path } from '@utils/routes';
import { convertSiretsString } from '../../../utils/formatMandateBody';

export const DownloadTemplateFile = () => {
  const form = useInstallationForm();

  const name = form.watch('mandate.signatory_name');
  const email = form.watch('mandate.signatory_email');
  const sirets = convertSiretsString(form.watch('mandate.sirets'));

  const disabled = !name || !email || sirets.length === 0;

  const onClick = () => {
    window.location.href = template_pdf_v2_mandates_path({ name, email, sirets });
  };

  return (
    <Button variant="link" onClick={onClick} disabled={disabled} icon="Download" size="xs" text="common.download" />
  );
};
