import { Option } from '@utils/types/common-types';
import { RiskAnalysisFocusType, SensitivityMetricType, ValuationMetricType } from './types';

export const FOCUS_OPTIONS: Option<RiskAnalysisFocusType>[] = [
  { label: 'risk_analysis.contract_type', value: 'contract_type' },
  { label: 'risk_analysis.energy', value: 'energy' },
];

export const GRAPPE_OPTIONS: Option<string>[] = [];

export const VALUATION_METRICS_FORM_NAMES_TO_LABELS = {
  volume: 'Volume',
  volume_not_signed: 'Volume Not Signed',
  open_volume: 'Open Volume',
  signed_volume: 'Signed Volume',
  signed_hedged_volume: 'Signed Hedged Volume',
  signed_non_hedged_volume: 'Signed Volume Not Hedged',
  cfd_signed_volume: 'Offtake Contract Signed Volume',
  cfd_not_signed_volume: 'Offtake Contract Volume Not Signed',
  hcr: 'HCR',
  mtom: 'MtoM',
  net_price: 'Net Price',
  subsidy_price: 'OA/CfD Price',
  float_price: 'Float Price',
};

export const SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS = {
  price_outright: 'Price Outright',
  profile_risk: 'Profile Risk',
  balancing_risk: 'Balancing Risk',
};

export const SPLIT_BY_METRICS_ONLY_COLUMNS = ['MtoM'];
export const SPLIT_BY_METRICS_ONLY_LINES = ['Net Price', 'Float Price', 'OA/CfD Price'];
export const ALL_SPLIT_BY_ONLY_METRICS = [...SPLIT_BY_METRICS_ONLY_COLUMNS, ...SPLIT_BY_METRICS_ONLY_LINES];

const SENSITIVITY_VOLUME_METRICS = ['Total'];
export const VALUATION_VOLUME_METRICS = ['Volume Not Signed', 'Signed Volume Not Hedged', 'Signed Hedged Volume'];
export const VALUATION_MAIN_VOLUME = ['Volume'];
export const VALUATION_OFFTAKE_SIGNED_VOLUME = ['Offtake Contract Signed Volume', 'Offtake Contract Volume Not Signed'];
export const REGULAR_COLUMN_METRICS = [
  ...VALUATION_VOLUME_METRICS,
  ...SENSITIVITY_VOLUME_METRICS,
  ...VALUATION_OFFTAKE_SIGNED_VOLUME,
];

const REGULAR_LINE_METRICS = ['HCR'];
export const ALL_REGULAR_CHART_METRICS = [...REGULAR_COLUMN_METRICS, ...REGULAR_LINE_METRICS];

export const CR_METRICS = [
  ...ALL_REGULAR_CHART_METRICS.filter(
    (metric) => !['Signed Volume Not Hedged', 'Signed Hedged Volume'].includes(metric),
  ),
];

export const VALUATION_VOLUMES = [
  'volume',
  'open_volume',
  'signed_volume',
  'volume_not_signed',
  'signed_hedged_volume',
  'signed_non_hedged_volume',
  'cfd_signed_volume',
  'cfd_not_signed_volume',
  'hcr',
];

export const VALUATIONS_PRICES = ['net_price', 'subsidy_price', 'float_price'];

export const VALUATION_METRICS_PER_TYPE: Record<ValuationMetricType, string[]> = {
  volume: VALUATION_VOLUMES,
  price: VALUATIONS_PRICES,
  mtom: ['mtom'],
};

export const SENSITIVITY_METRICS_PER_TYPE: Record<SensitivityMetricType, string[]> = {
  price_outright: ['price_outright'],
  profile_risk: ['profile_risk'],
  balancing_risk: ['balancing_risk'],
};
