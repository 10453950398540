import React, { ComponentType, useMemo } from 'react';
import { useMandatesContext } from '@context/Mandates.context';
import { Button } from '@GDM/Button';
import { Select } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { GroupBase, OptionProps } from 'react-select';
import { MandateOption } from './mandate-select.types';
import { MandateSelectBadge, MandateSelectOption } from './MandateSelectOption';

const MandateSelect = ({ openNewMandateForm }: { openNewMandateForm: () => void }) => {
  const form = useInstallationForm();

  const { data: mandates, loaded: mandatesLoaded } = useMandatesContext();
  const { rules, disabled } = useInstallationField('mandate_uuid');

  const {
    query: { data: installation },
  } = useInstallationFormQuery();

  const installationCompanyNumber = installation?.owner_company_number;

  const options: MandateOption[] = useMemo(() => {
    if (!mandates) return [];

    return mandates
      ?.filter((mandate) =>
        mandate.sirens_with_company_names.some((company) => company.company_number === installationCompanyNumber),
      )
      ?.map((mandate) => ({ label: mandate.representative, value: mandate.uuid, status: mandate.status }))
      .sort(sortOptionsByLabelAsc)
      .sort((a, b) => (a.status === 'signed' ? -1 : 1) - (b.status === 'signed' ? -1 : 1));
  }, [mandates, installationCompanyNumber]);

  return (
    <Controller
      control={form.control}
      name="mandate_uuid"
      rules={rules}
      render={({ field, fieldState }) => (
        <div>
          <Select
            options={options}
            classNamePrefix="select-mandate"
            onChange={(option) => field.onChange(option?.value)}
            components={{
              Option: MandateSelectOption as unknown as ComponentType<
                OptionProps<Option<string>, boolean, GroupBase<Option<string>>>
              >,
            }}
            selectedOption={field.value}
            label="admin.installations.select_mandate"
            size="lg"
            isClearable
            isLoading={!mandatesLoaded}
            isDisabled={disabled || !installationCompanyNumber}
            formatOptionLabel={(data) => (
              <MandateSelectBadge status={(data as MandateOption).status} label={(data as MandateOption).label} />
            )}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
          />

          <Button
            size="xs"
            text="admin.installations.new_mandate"
            variant="link"
            icon="Plus"
            onClick={openNewMandateForm}
            className="my-1"
          />
        </div>
      )}
    />
  );
};

export default MandateSelect;
