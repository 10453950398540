import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Chart } from '@GDM/Chart/Chart';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  VALUATION_METRICS_PER_TYPE,
  SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS,
  VALUATION_METRICS_FORM_NAMES_TO_LABELS,
  SENSITIVITY_METRICS_PER_TYPE,
} from '../constants';
import styles from '../styles.module.scss';
import {
  RiskAnalysisReportType,
  GreenstarReportData,
  RiskAnalysisFormFilters,
  GreenstarReportDataWithOneFocus,
} from '../types';
import { useRiskAnalysisTable } from './hooks/table';
import { useRiskAnalysisCharts } from './hooks/useCharts';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReportVisualisation = ({
  data: rawData,
  isReportNested,
  isSplitByMetric: rawIsSplitByMetric,
  type,
  metricGroup,
  display,
  title,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  isSplitByMetric: boolean;
  type: RiskAnalysisReportType;
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
  display: RiskAnalysisFormFilters['display'];
  title?: string;
}) => {
  const [expanded, setExpanded] = useState({});
  const isSensitivityChart = display === 'chart' && type === 'sensitivity';
  const isSplitByMetric = isSensitivityChart || rawIsSplitByMetric;
  const intermediateData = useFormatReportData(rawData, isReportNested, isSplitByMetric);
  const data = isSensitivityChart
    ? { Total: intermediateData.Total as GreenstarReportDataWithOneFocus }
    : intermediateData;

  const { columns, rows } = useRiskAnalysisTable(data, isReportNested);

  const metricsToLabel =
    type === 'valuation' ? VALUATION_METRICS_FORM_NAMES_TO_LABELS : SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS;

  const metricsPerType = type === 'valuation' ? VALUATION_METRICS_PER_TYPE : SENSITIVITY_METRICS_PER_TYPE;

  const columnFilters = useMemo<[{ id: string; value: string[] }] | []>(() => {
    if (metricGroup === 'all') {
      return [];
    } else {
      const blackList = Object.entries(metricsPerType).reduce<string[]>(
        (list, [metricType, metrics]) => (metricType === metricGroup ? list : [...list, ...metrics]),
        [],
      );

      return [
        {
          id: 'name',
          value: blackList.map((metric) => {
            return metricsToLabel[metric as keyof typeof metricsToLabel];
          }),
        },
      ];
    }
  }, [metricGroup, metricsPerType, metricsToLabel]);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    state: {
      expanded,
      columnFilters,
    },
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useLayoutEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  const chartsDataList = useRiskAnalysisCharts({
    data,
    isReportNested,
    splitByMetric: isSplitByMetric,
    metricsBlackList: columnFilters?.[0]?.value || [],
  });

  const charts = chartsDataList.map((chart) => (
    <Chart
      {...chart}
      options={{ ...chart.options, title: { ...chart.options.title, text: title || chart.options.title?.text } }}
      key={chart.key}
    />
  ));

  return (
    <>
      {display === 'table' && (
        <>
          {title && <h3 className="upper-case">{title}</h3>}
          <Table>
            <TableHead table={table} />
            <TableBody table={table} />
          </Table>
        </>
      )}
      {display === 'chart' && (isSensitivityChart ? charts : <div className={styles['chart-grid']}>{charts}</div>)}
    </>
  );
};
