import React, { useRef } from 'react';
import { Button } from '@GDM/Button';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { MandateFormType } from '@utils/types/mandate';

const UploadButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <Button
      variant="primary-2"
      size="xs"
      disabled={disabled}
      className="ml-2"
      icon="Upload"
      text="admin.installations.insert_mandate"
    />
  );
};

export const UploadMandateInput = ({
  file,
  setFormData,
  disabled,
  setValuesHasChanged,
}: {
  file: File | null;
  setFormData: (key: keyof MandateFormType, value: string | FileList | null) => void;
  setValuesHasChanged: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleUploadButtonClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target?.files) {
      setFormData('mandate_file', e.target?.files);
      setValuesHasChanged(true);
    }
  };

  return (
    <>
      <div className="d-flex my-3" onClick={handleUploadButtonClick}>
        <Input flexFull type="text" placeholder={file?.name ?? 'Inserer un mandat signé'} disabled={disabled} />

        <UploadButton disabled={disabled} />

        <input
          disabled={disabled}
          type="file"
          ref={fileRef}
          hidden
          accept=".pdf"
          onChange={handleFileUpload}
          data-cy="mandate-file-input"
        />
      </div>

      {file ? (
        <p className="mt-1" dangerouslySetInnerHTML={{ __html: t('admin.installations.insert_mandate_warning') }} />
      ) : null}
    </>
  );
};
