import React from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';

export const SignatoryEmailInput = ({ disabled }: { disabled?: boolean }) => {
  const form = useInstallationForm();

  return (
    <ControlledInput
      control={form.control}
      disabled={disabled}
      label="admin.installations.signatory_email"
      name="mandate.signatory_email"
      type="text"
      size="lg"
      data-cy="signatory-email-input"
      flexFull
    />
  );
};
