import type { RequestErrorBody } from '@hooks/useRequest';
import type { UseMutateFunction } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { InstallationFormRequestBody } from '../installation.types';

export type MutateFunction = UseMutateFunction<unknown, AxiosError<RequestErrorBody>, InstallationFormRequestBody>;
export type MutationActions = { create: MutateFunction; update: MutateFunction; isEdit: boolean };

export const submitFormStep = (values: InstallationFormRequestBody, actions: MutationActions) => {
  if (actions.isEdit) actions.update(values);
  else actions.create(values);
};
