import React, { useEffect } from 'react';
import { getContract } from '@api/contracts';
import Page from '@pages/Page';
import { useQuery } from '@tanstack/react-query';
import { ContractForm } from '@utils/types/contract/form';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import { Path } from 'react-hook-form';
import styles from './contract.module.scss';
import { Form } from './Form/Form';
import { serializeContract } from './Form/helpers';
import { FormAlerts } from './FormAlerts/FormAlerts';
import { ContractFormMode } from './hooks/useContractForm/types';
import { useContractForm } from './hooks/useContractForm/useContractForm';
import { useSubmitMutation } from './hooks/useSubmit';
import { PageActions } from './PageActions';
import { PageSubtitle } from './PageSubtitle';

export const ContractPage = ({
  user,
  contractUuid,
  offtaker,
  readonly,
}: {
  user: User;
  contractUuid?: string;
  offtaker?: MarketPlayer;
  readonly?: boolean;
}) => {
  const {
    data: contract,
    isPending,
    error,
  } = useQuery({
    queryKey: ['contract', contractUuid],
    queryFn: async () => {
      return contractUuid ? getContract(contractUuid) : null;
    },
  });

  const readOnly = Boolean(readonly);

  let mode: ContractFormMode = contractUuid ? 'edit' : 'create';
  mode = readonly ? 'view' : mode;

  const { sections, globals, queries, form } = useContractForm({
    contract,
    offtaker,
    mode,
  });
  const { setError } = form;

  const submitMutation = useSubmitMutation(contractUuid);

  useEffect(() => {
    if (submitMutation.isError && submitMutation.error.errors) {
      submitMutation.error.errors.forEach((fieldError) => {
        if (fieldError.field)
          setError(fieldError.field as Path<ContractForm>, {
            message: fieldError.code || 'errors.unknown_error',
          });
      });
    }
  }, [submitMutation.error, submitMutation.isError, setError]);

  let title = 'contracts.titles.new';
  if (readonly) title = 'contracts.titles.view';
  if (!readonly && contractUuid) title = 'contracts.titles.edit';

  return (
    <form
      className={classNames(styles.container, styles['full-height-layout'])}
      onSubmit={form.handleSubmit(() => {
        const values = form.getValues();
        const data = serializeContract(values, globals);

        submitMutation.mutate({ ...data, status: 'complete' });
      })}
    >
      <Page
        title={title}
        subtitle={<PageSubtitle {...contract} />}
        error={error?.message}
        isLoading={isPending}
        user={user}
        pageActions={
          <PageActions
            readOnly={readOnly}
            submitDraft={() => {
              const requiredValues = REQUIRED_FIELDS_FOR_DRAFT.map((field) => ({
                field,
                value: form.getValues(field),
              }));
              const missingFields = requiredValues.filter(({ value }) => !value);

              missingFields.forEach(({ field }) => {
                form.trigger(field);
              });

              if (missingFields.length > 0) return;

              const values = form.getValues();
              const data = serializeContract(values, globals);

              submitMutation.mutate({ ...data, status: 'draft' });

              return;
            }}
            conractUuid={contractUuid}
            showSaveDraft={globals.installation?.status === 'draft' || mode !== 'view'}
          />
        }
        layout="no-background"
      >
        <FormAlerts mode={mode} submitMutation={submitMutation} />
        <Form
          form={form}
          isLoading={isPending}
          readOnly={readOnly}
          sections={sections}
          globals={globals}
          queries={queries}
          showProgress
          showHelpBoxes
        />
      </Page>
    </form>
  );
};

const REQUIRED_FIELDS_FOR_DRAFT: Path<ContractForm>[] = ['type', 'installation_uuid'];
