import React from 'react';
import { ModelSelect } from '../../fields/ModelSelect';
import { SerialNumberInput } from '../../fields/SerialNumberInput';
import { DataProvider } from './DataProvider';
import styles from './default.module.scss';
import { DirectConnection } from './DirectConnection';

export const DefaultItPtEs = () => {
  return (
    <div className={styles.container}>
      <DataProvider />
      <DirectConnection>
        <div className="d-flex gap-3 mt-3 hide-empty">
          <ModelSelect />
          <SerialNumberInput />
        </div>
      </DirectConnection>
    </div>
  );
};
