import { createMandate, updateMandate, getMandates } from '@api/mandates';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import type { Mandate } from '@utils/types/mandate';
import type { AxiosError } from 'axios';
import type { UseFormSetValue } from 'react-hook-form';
import { formatUpsertBody } from '../Mandate/utils/formatMandateBody';
import type { NextStepHandler } from './hooks/useStepsNavigation';
import type { InstallationForm } from './installation.types';

const onSuccess = (
  data: Mandate,
  queryClient: QueryClient,
  goToNextStep: NextStepHandler,
  setFormValue: UseFormSetValue<InstallationForm>,
) => {
  queryClient.setQueryData<Mandate[]>(['mandates'], (prevMandates) => {
    return prevMandates ? [...prevMandates, data] : [data];
  });

  queryClient.setQueryData<InstallationWithMeterInfo>(['installation-name'], (prevInstallation) => {
    if (prevInstallation && prevInstallation.meter)
      return { ...prevInstallation, meter: { ...prevInstallation.meter, mandate_uuid: data.uuid } };
    else return prevInstallation;
  });

  setFormValue('mandate_uuid', data.uuid);
  goToNextStep();
};

export const useMandateFormQuery = (props: {
  uuid: string | null;
  goToNextStep: () => void;
  setFormValue: UseFormSetValue<InstallationForm>;
}) => {
  const { goToNextStep, setFormValue, uuid } = props;
  const queryClient = useQueryClient();

  const query = useQuery<Mandate[], AxiosError<RequestErrorBody>>({
    queryKey: ['mandates'],
    queryFn: () => getMandates(),
  });

  const createMutation = useMutation<Mandate, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) =>
      createMandate(
        formatUpsertBody(
          body.mandate,
          body.country, // default country in case mandate country is missing
          body.grid_id, // default grid id
        ),
      ),
    onSuccess: (data) => onSuccess(data, queryClient, goToNextStep, setFormValue),
  });

  const updateMutation = useMutation<Mandate, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) =>
      updateMandate(
        uuid,
        formatUpsertBody(
          body.mandate,
          body.country, // default country in case mandate country is missing
        ),
      ),
    onSuccess: (data) => onSuccess(data, queryClient, goToNextStep, setFormValue),
  });

  return { query, createMutation, updateMutation };
};
