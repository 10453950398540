import { useCallback, type Dispatch, type SetStateAction } from 'react';
import type { Step } from '@GDM/forms';
import { v2_installations_path } from '@utils/routes';
import type { UseFormClearErrors } from 'react-hook-form';
import type { InstallationForm, StepKey } from '../installation.types';

export const useStepsNavigation = (
  setSelectedStep: Dispatch<SetStateAction<StepKey>>,
  selectedStep: StepKey,
  steps: { [key in StepKey]?: Step | undefined },
  stepSize: number,
  clearErrors: UseFormClearErrors<InstallationForm>,
) => {
  const stepKeys = Object.keys(steps) as StepKey[];

  const handleNextStep = useCallback<NextStepHandler>(
    (redirectDirectly) => {
      clearErrors();

      const isLastStep = stepKeys.indexOf(selectedStep) === stepKeys.length - 1;

      if (isLastStep || redirectDirectly) {
        window.location.href = v2_installations_path();

        return;
      }

      setSelectedStep((currentStep) => {
        const currentIndex = stepKeys.indexOf(currentStep);
        if (currentIndex + stepSize >= stepKeys.length - 1) return stepKeys[stepKeys.length - 1];

        const newSelectedStep = stepKeys[currentIndex + stepSize];

        return newSelectedStep;
      });
    },
    [stepKeys, setSelectedStep, clearErrors, selectedStep, stepSize],
  );

  const handlePreviousStep = useCallback(() => {
    clearErrors();
    const currentIndex = stepKeys.indexOf(selectedStep);
    if (currentIndex - stepSize < 0) return stepKeys[0];

    const newSelectedStep = stepKeys[currentIndex - stepSize];
    setSelectedStep(newSelectedStep);
  }, [selectedStep, stepKeys, setSelectedStep, clearErrors, stepSize]);

  return { handleNextStep, handlePreviousStep };
};

export type NextStepHandler = (redirectDirectly?: boolean) => void;
