import React from 'react';
import { Button } from '@GDM/Button';
import { download_v2_mandate_path } from '@utils/routes';
import type { Mandate } from '@utils/types/mandate';

export const DownloadOriginalFile = ({ mandate }: { mandate: Mandate }) => {
  const onClick = () => {
    window.location.href = download_v2_mandate_path(mandate.uuid);
  };

  return <Button variant="link" onClick={onClick} icon="Download" size="xs" text="common.download" />;
};
